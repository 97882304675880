.expert_appointments_container {
    min-width: 250px;
    // max-width: 450px;
}

.showExpertModal {
    .profileBanner {
        width: 100%;
        height: 160px;
        position: relative;
        margin-bottom: 7rem;
        border-radius: 5px;
        background-image: url("/assets/images/dashboardMenu.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;

        img {
            max-width: 200px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -100px;
            border-radius: 100%;
            overflow: hidden;
            border: 3px solid $white;
        }
    }

    .badge {
        display: block;
        font-size: 1rem;
        line-height: 1.5rem;
        border-radius: 20px;
        margin: 0.5rem 1rem;
        @include boxShadowMixin($offX: map-get($pop_shadow, "offX"),
            $offY: map-get($pop_shadow, "offY"),
            $blur: map-get($pop_shadow, "blur"),
            $spread: map-get($pop_shadow, "spread"),
            $color: map-get($pop_shadow, "color"),
            $alpha: map-get($pop_shadow, "alpha"));

        &-light {
            background-color: $white;
        }
    }

    .section-title {
        position: relative;
        margin: 2rem 0;

        h2 {
            z-index: 1;
            display: inline;
            background-color: $white;
            position: relative;
        }

        span.title-border {
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            display: inline-block;
            border-bottom: 1px solid $secondary;
            z-index: 0;
        }
    }

    .infoCard {
        .card-title {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            font-size: 1.3rem;

            .svgIcon {
                svg {
                    path {
                        fill: $primary;
                        transition: all 0.15s ease-in-out;
                    }
                }

                &.star {
                    svg path {
                        fill: $accent;
                    }
                }

                &:hover {

                    &.editFunction svg path,
                    &.deleteFunction svg path {
                        fill: $secondary;
                    }
                }
            }
        }

        h3 {
            font-size: 2rem;
        }

        h5 {
            font-size: 1rem;
            font-weight: 500;
        }

        p {
            margin: 0;

            &.company {
                font-size: 2rem;
            }

            &.timeLapse {
                font-size: 1.7rem;
                font-weight: bold;
                color: $secondary;
            }

            &.field {
                font-weight: bold;
                line-height: 0.9rem;
            }

            &.website {
                font-size: 1rem;
                line-height: 0.9rem;
            }
        }
    }

    button.requestButton {
        display: block;
        margin: 10px 0;
        margin-left: auto;
        @include customButton();
        @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
        padding: 10px 30px;
        border-radius: 40px;
        margin-top: 1rem;
        font-weight: 500;

        &:hover {
            @include linearGradientBackground($startOpacity: 0.7, $endOpacity: 0.7);
        }
    }
}