.userSettings {
  .card {
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    margin-top: 1rem;
    color: $secondary;
    transition: color 0.15s ease-in-out;
    i, p {
      display: inline-block;
      width: auto;
      margin: 0;
      margin-left: 0.5rem;
    }
    &:hover {
      color: $primary;
    }
  }
}