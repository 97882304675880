.calendarView {

  .navigate-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .rbc-calendar {
    min-height: 80vh;
  }

  .buttonAction {
    @include customButton($width: 150px);
    @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
    border-radius: 40px;
    margin-top: 1rem;
    transition: all 0.15s ease-in-out;

    &:hover {
      @include linearGradientBackground($startOpacity: 0.7, $endOpacity: 0.7);
    }
  }
}

.rbc-event {
  min-height: 40px;
}