.login {
  position: relative;
  z-index: 0;

  &.Admin {
    background-image: url("/assets/images/admin.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    // z-index: 1;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba($primary, 0.4);
    background: -moz-linear-gradient(45deg, rgba($primary, 0.4) 0%, rgba($secondary, 0.4) 100%);
    background: -webkit-gradient(left bottom,
        right top,
        color-stop(0%, rgba($primary, 0.4)),
        color-stop(100%, rgba($secondary, 0.4)));
    background: -webkit-linear-gradient(45deg, rgba($primary, 0.4) 0%, rgba($secondary, 0.4) 100%);
    background: -o-linear-gradient(45deg, rgba($primary, 0.4) 0%, rgba($secondary, 0.4) 100%);
    background: -ms-linear-gradient(45deg, rgba($primary, 0.4) 0%, rgba($secondary, 0.4) 100%);
    background: linear-gradient(45deg, rgba($primary, 0.4) 0%, rgba($secondary, 0.4) 100%);
  }

  .header {
    z-index: 3;
    text-align: center;

    h1 {
      color: $white;
    }

    .subheader {
      color: $white;
      width: 70%;
      min-width: 250px;
      max-width: 300px;
      margin: 30px auto;
      font-weight: 100;
    }

    .selectorsContainer {
      list-style: none;

      .selector {
        cursor: pointer;
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: rgba($white, 0.5);
        margin: 5px;
        border-radius: 100%;

        &.active {
          background-color: rgba($white, 0.8);
        }
      }
    }
  }

  .buttonsContainer {
    z-index: 3;

    button {
      display: block;
      margin: 10px auto;
      @include customButton;

      &.active {
        background-color: $white;
        color: $primary;
      }
    }
  }

  .termsAnchor {
    color: $white;

    &:hover {
      color: rgba($white, 0.5);
    }
  }
}

.descriptionLogin {
  img {
    width: 100%;
    max-width: 450px;
    height: auto;
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    width: 80%;
    min-width: 250px;
    max-width: 400px;
    text-align: right;
    font-weight: 100;
  }

  .PrivacyAnchor {
    &:hover {
      color: rgba($primary, 0.5);
    }
  }
}

.PrivacyAnchor,
.termsAnchor {
  font-weight: 200 !important;
  position: absolute;
  bottom: 5%;
  text-align: center;
  cursor: pointer;
}

.swal2-container {
  .swal2-title {
    color: $primary;
  }

  .swal2-content {
    .swal2-actions {
      .swal2-confirm {
        @include customButton($width: auto,
          $borderColor: $white,
          $textColor: $white,
          $backColor: $primary,
          $hoverBackColor: $white);
        border: none;
      }
    }
  }
}

.loginModal {
  max-height: 600px !important;

  .loginLogoImage {
    width: 100%;
    height: auto;
    max-width: 200px;
  }

  .modalInformation {
    @include flexElement($direction: column, $justify: space-around, $align: flex-start, $wrap: nowrap);
    color: $primary;
    text-align: center;
    height: 100%;

    // overflow-y: auto;
    .loginBody {
      h2 {
        margin: 1.5rem 0;
        font-weight: 500;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        margin: 2.5rem auto;
      }
    }

    button#SignUpButton {
      @include customButton($width: auto,
        $borderColor: $primary,
        $textColor: $primary,
        $backColor: $white,
        $hoverBackColor: rgba($primary, 0.25));
    }

    .modalButtonsContainer {
      width: 100%;
    }
  }
}