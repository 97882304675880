.sidebar {
  justify-content: space-around;
  height: 100vh;
  max-width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url("/assets/images/dashboardMenu.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  > div {
    max-width: 90%;
  }
  .userProfileInfo {
    color: $white;
    .userProfileImage {
      max-width: 100px;
      margin: auto;
      display: block;
      border: 3px solid $white;
    }
    p {
      margin: 1rem 0;
      text-align: center;
    }
    hr {
      border-color: $white;
    }
  }
  .menuLinksContainer {
    a {
      font-size: 1rem;
      text-decoration: none;
      color: $secondary;
      margin: 15px 5px;
      display: block;
      transition: all 0.15s ease-in-out;
      @include flexElement($direction: row, $align: center);
      svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        path {
          fill: $secondary;
          transition: all 0.15s ease-in-out;
        }
      }
      &:hover {
        color: $white;
        svg path {
          fill: $white;
        }
      }
      i {
        padding-right: 0.2rem;
      }
    }
  }
  .customPointer {
    cursor: pointer;
    color: $secondary;
    &:hover {
      color: $white;
    }
  }
  .menuBottom {
    svg {
      width: 100%;
      max-width: 180px;
      height: auto;
      path {
        fill: $white;
      }
    }
  }
}
