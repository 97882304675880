:root {
  font-size: $root_size;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: $body_fz;
  color: $primary;
  overflow-x: hidden;

  &.overflow-hidden {
    overflow: hidden;
  }
}

h1,
.h1 {
  font-size: $h1_font-size;
  font-weight: bold;
}

h2,
.h2 {
  font-size: $h2_font-size;
  font-weight: bold;
}

h3,
.h3 {
  font-size: $h3_font-size;
  font-weight: bold;
}

h4,
.h4 {
  font-size: $h4_font-size;
  font-weight: bold;
}

h5,
.h5 {
  font-size: $h5_font-size;
  font-weight: bold;
}

h6,
.h6 {
  font-size: $h6_font-size;
  font-weight: bold;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

input p,
label,
input,
textarea,
button,
a {
  font-size: $general_fz;
}

input,
select,
textarea,
button {
  outline: none !important;
  box-shadow: none !important;
  caret-color: $secondary;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

button.btn {
  @include customButton($width: auto,
    $borderColor: $primary,
    $textColor: $primary,
    $backColor: $white,
    $hoverBackColor: rgba($primary, 0.25));
  // color: $white;
}

button.linear_btn {
  @include linearGradientBackground($startColor: $secondary, $endColor: $primary);

  &:hover {
    @include linearGradientBackground($startColor: $secondary, $endColor: $primary, $startOpacity: 0.4, $endOpacity: 0.4);
  }
}

select,
select.custom-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("/assets/images/dropdown.svg") no-repeat right $white;
}

.form-group {
  white-space: nowrap;
  @include flexElement($justify: center, $align: center, $wrap: nowrap);

  .form-control[readonly] {
    background-color: rgba($grey3, 0.3);
    border-radius: 5px !important;

    &.noDisabled {
      background-color: transparent;
    }
  }

  .floatLabel {
    position: absolute;
    left: 35px;
    top: -0.7rem;
    opacity: 0;
    color: $primary;
    background-color: $white;
    font-size: 0.8rem;
    transition: all 0.25s ease-in-out;

    &.float {
      opacity: 1;
      color: $secondary;
    }
  }

  input.form-control,
  select.form-control,
  textarea {
    border: none;
    border-bottom: 2px solid $gray;
    border-radius: 0 !important;
    display: inline-block;
    transition: border 0.25s ease-in-out;

    &:active,
    &:focus,
    &:not(:focus),
    &:visited {
      border-color: $secondary;
    }

    &::-webkit-input-placeholder {
      font-weight: 100 !important;
    }

    &::-moz-placeholder {
      font-weight: 100 !important;
    }

    &::-ms-input-placeholder {
      font-weight: 100 !important;
    }

    &::placeholder {
      font-weight: 100 !important;
    }
  }

  textarea.form-control {
    border: none;
    background-color: rgba($secondary, 0.08);
  }

  label {
    margin-bottom: 0;

    .svgIcon {
      width: 20px;
    }
  }
}

.react-tel-input input[type="text"],
.react-tel-input input[type="tel"] {
  // max-width: 300px;
  width: 100%;
}

a,
button {
  cursor: pointer;

  &:active,
  &:focus,
  &:visited {
    outline: none !important;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.footerLogo {
  svg {
    max-width: 130px;
    height: auto;
  }
}

.headerLogo {
  font-size: 1.5rem;

  svg {
    max-width: 130px;
    height: auto;
  }
}

// Sweet alert styles
.closeButton {
  position: absolute;
  z-index: 10;
  height: 25px;
  top: 10px;
  right: 5px;
  padding: 0;
  font-size: 25px;
  border: none;
  background-color: transparent;

  svg {
    width: 25px;
    height: auto;

    path {
      transition: all 0.25s ease-in-out;
      fill: $primary;
    }
  }

  &:hover {
    background-color: transparent;
    background-image: none !important;

    svg path {
      fill: $secondary;
    }
  }
}

// Layout Styles
.App {
  min-height: 100vh;
}

.fullHeight {
  min-height: 100vh;
  @include flexElement($direction: column);
  padding: 0;

  .loginViews {
    width: 100%;
    flex: 1;

    h1 {
      color: $secondary;
      font-size: 2rem;
      letter-spacing: 0.15rem;
    }

    h5 {
      font-size: 1.3rem;
      max-width: 90%;
    }
  }
}

.AppFrame {
  .dashboardContent {
    min-height: 100vh;
  }
}

.languageSelector {
  span {
    cursor: pointer;
    transition: color 0.15s ease-out;

    &:hover {
      color: $secondary;
    }
  }
}

.flexElementColumn {
  @include flexElement($direction: column, $justify: center, $align: center);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.rowElement {
  @include flexElement();
}

.colElement {
  @include flexElement($direction: column);
  display: unset;
}

.svgIcon {
  display: inline-block;
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: auto;

    path {
      fill: $secondary;
      transition: all 0.15s ease-in-out;
    }
  }
}

.customPointer {
  cursor: pointer;
  color: $primary;
  margin: 0;
  transition: all 0.15s ease-in-out;

  &:hover {
    color: $secondary;

    svg path {
      fill: $primary;
    }
  }
}

.AppFrame {
  width: 100vw;
  overflow: hidden;
}

.card {
  border-radius: 10px;
  border: none;
  @include boxShadowMixin($offX: map-get($pop_shadow, "offX"),
    $offY: map-get($pop_shadow, "offY"),
    $blur: map-get($pop_shadow, "blur"),
    $spread: map-get($pop_shadow, "spread"),
    $color: map-get($pop_shadow, "color"),
    $alpha: map-get($pop_shadow, "alpha"));
}

.ReactModal__Body--open {
  overflow: hidden;

  .ReactModalPortal,
  .Overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .ReactModal__Content {
      -webkit-animation-fill-mode: none;
      animation-fill-mode: none;
      position: relative;
      width: 90%;
      height: 100%;
      max-width: 900px;
      max-height: 550px;
      padding: 1rem;
      border-radius: 5px;
      overflow: scroll;
      padding-top: 1rem;
      padding-bottom: 1rem;
      background-color: white;

      &.fullWidthModal {
        height: 90%;
        max-width: unset;
        max-height: 800px;
        overflow-y: scroll;
      }

      .stepComponent {
        height: 100%;
        overflow-y: auto;

        .buttonAction {
          max-height: 40px;
          @include customButton($width: 150px);
          @include linearGradientBackground($startColor: $secondary,
            $endColor: $primary);
          border-radius: 40px;
          margin-top: 1rem;
          transition: all 0.15s ease-in-out;

          &:hover {
            @include linearGradientBackground($startOpacity: 0.7,
              $endOpacity: 0.7);
          }
        }
      }

      .button_accept {
        @include customButton($width: auto);
        @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
        padding: 5px 15px;
        border-radius: 40px;
        border: none;
        margin: 0 0.5rem;
        min-width: unset;
      }

      .button_reject {
        @include customButton($width: auto,
          $textColor: $black,
          $borderColor: $black,
          $hoverBackColor: $primary,
          $hoverTextColor: $white);
        margin: 0 0.5rem;
        min-width: unset;
      }
    }
  }

  .ReactModalPortal {
    z-index: 9999;
  }

  .Overlay {
    background-color: rgba($black, 0.3);
    @include flexElement($justify: center, $align: center);

    &:before {
      content: “ ”;
      background: inherit;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      box-shadow: inset 0 0 0 3000px rgba(255, 255, 255, 0.3);
      filter: blur(10px);
    }
  }
}

.swal2-actions {
  .swal2-confirm {
    @include customButton($width: auto !important,
      $borderColor: $white !important,
      $textColor: $white !important,
      $backColor: $primary !important,
      $hoverBackColor: $white !important,
      $hoverTextColor: $primary !important,
      $hoverBorderColor: transparent !important);
    border: none;
  }

  .swal2-cancel {
    position: absolute;
    z-index: 10;
    height: auto;
    top: 5px;
    right: 5px;
    padding: 0;
    font-size: 25px;
    border: none;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
    line-height: 0;
    padding: 5px;
    border-radius: 20px;

    i {
      color: $primary;
    }

    &:hover {
      background-color: $primary;

      i {
        color: $white;
      }
    }
  }
}

.form-control[readonly] {
  background-color: transparent;
}

.react-timekeeper {
  display: block !important;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.skillsCard {
  .card-title {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    font-size: 1.3rem;
  }

  h2 {
    color: $secondary;
  }

  .body-description {
    font-weight: 600;
    margin: 0;
  }
}


body.full_chat {
  #header {
    margin-right: 2rem;
  }

  .enlarge_icon {
    position: fixed !important;
    z-index: 10000001 !important;
    right: 1rem !important;
    top: 65px !important;
    left: unset !important;
    bottom: unset !important;
  }

  .videochat_modal {
    width: 99vw !important;
    height: 99vh !important;
    max-width: unset !important;
    max-height: unset !important;

    .chatcomponent_container .OT_subscriber {
      width: 100vw !important;
      height: 100vh !important;
      z-index: 10000000;
      position: fixed;
      left: 0;
      top: 0;
    }

    .chatcomponent_container .OT_publisher {
      position: fixed;
      right: 1rem;
      bottom: 1rem;
    }

    .chat_banner {
      position: fixed;
      bottom: 0;
      z-index: 10000001;
    }
  }

  .under_chat {
    display: none !important;
  }

  .chat_view {
    width: 100% !important;
    max-width: unset;
  }

  .textchat_view {
    display: none !important;
  }
}



.switch {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  width: 60px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  text-align: center;
  line-height: 20px;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 2px;
    background-color: rgba($white, 0.5);
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 0;
  }
}

input:checked+.slider {
  @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
  // background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.message {
  margin-bottom: 0;
  margin-top: 1rem;
  padding: 10px 30px;
  border-bottom: 1px solid $secondary;
  font-size: 1.2rem;
  color: $black;
  position: relative;

  &:last-of-type {
    border-bottom: none;
  }

  span {
    text-align: center;
    display: block;

    &.label {
      position: absolute;
      color: $secondary;
      left: 15px;
      top: -10px;
      font-size: 0.85rem;
    }
  }
}

#accordion {
  border: 1px solid $secondary;
  border-radius: 10px;
  margin-top: 2rem;
  overflow: hidden;

  .card {

    &.headerCard {
      border-radius: 0;
    }

    .card-header {
      border: none;
      border-radius: 0;
      padding: 0;
      overflow: hidden;
    }

    .customPaymentCard {
      border-radius: 10px;
      margin: 0 1rem;
      border: 1px solid $secondary;
    }

    .btn-link {
      color: $primary;
      text-transform: uppercase;
      width: 100%;
      text-align: left;
      position: relative;
      background-color: rgba($secondary, 0.1);
      padding: .75rem 1.25rem;
      border-radius: 0;
      border: none;
      max-width: unset;

      .icon {
        font-family: FontAwesome;
        float: right;

        &:before {
          content: "\f068";
        }
      }

      &.collapsed .icon:before {
        content: "\f067";
      }
    }
  }
}

.table-hover tbody tr:hover {
  background-color: rgba($secondary, 0.1);
}

table {
  tr {

    th,
    td {
      text-align: center;
      white-space: nowrap;
      vertical-align: middle !important;
    }

    td:first-of-type {
      text-align: left;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: rgba($secondary, 0.1);
    }
  }

  .buttonStyles {
    @include customButton($width:60px);
    @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
    min-width: unset;
    font-size: 1em;
    padding: 10px;
    margin: 5px;
    transition: all 0.25s ease-in-out;

    &:hover {
      background: $primary;
      color: $white;
    }
  }

  .switch {
    margin: auto;
  }

  .uploadResume {
    @include customButton($width: auto, $backColor: $primary);
    @include flexElement($justify: center, $align: center);
    margin: auto;

    &:hover i {
      color: $primary;
    }
  }

  .pageButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $primary;
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    font-size: 1em;
    margin: 2px;
    padding: 0;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

.rate {
  position: relative;
  margin: 0;
  max-width: 100px;
  margin: auto;

  svg {
    height: 17px;
    width: auto;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.re-btn {
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-round {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 0;
  text-align: center;
  line-height: 12px;
  font-size: 18px;
}

.icon-button {
  background-color: $white;
  color: $secondary;
  border-color: $secondary;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: rgba($primary, 0.8);
    border-color: rgba($primary, 0.8);
    color: $white;
  }
}

.contact-information {
  .inputsContainer {
    #first_name_container {
      order: 1;
    }

    #email_container {
      order: 4;
    }

    #mobile_phone_container {
      order: 7;
    }

    #last_name_container {
      order: 2;
    }

    #location_container {
      order: 5;
    }

    #office_phone_container {
      order: 8;
    }

    #middle_name_container {
      order: 3;
    }

    #time_zone_container {
      order: 6;
    }

    #time_contacte_container {
      order: 9;
    }

    @media (max-width: 767px) {
      #first_name_container {
        order: 1;
      }

      #email_container {
        order: 4;
      }

      #mobile_phone_container {
        order: 5;
      }

      #last_name_container {
        order: 2;
      }

      #location_container {
        order: 7;
      }

      #office_phone_container {
        order: 6;
      }

      #middle_name_container {
        order: 3;
      }

      #time_zone_container {
        order: 8;
      }

      #time_contacte_container {
        order: 9;
      }
    }
  }
}

.showAppointmentModal {
  .userImage {
    img {
      width: 250px;
      height: auto;
      border: 2px solid $white;
      @include boxShadowMixin($offX: map-get($hover_shadow, "offX"),
        $offY: map-get($hover_shadow, "offY"),
        $blur: map-get($hover_shadow, "blur"),
        $spread: map-get($hover_shadow, "spread"),
        $color: map-get($hover_shadow, "color"),
        $alpha: map-get($hover_shadow, "alpha"));
    }

    &.client {
      z-index: 2;
      position: relative;
    }

    &.expert {
      position: relative;
      z-index: 1;
      left: -2rem;
    }
  }

  @media screen and (max-width: 480px) {
    .btn-radio {
      display: block;
      float: none;

      &:not(:first-child) {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }

  .text_container {
    .header {
      font-size: $h3_font-size;
      font-weight: 400;
      color: $secondary;
    }

    .subheader {
      font-size: $h4_font-size;
      font-weight: 200;
    }
  }

  .bodyContainer {
    margin-top: 3rem;
    margin-bottom: 4rem;
    position: relative;

    .questionHeader {
      font-weight: 300;
      color: $secondary;
      position: relative;

      .svgIcon {
        position: absolute;
        right: 2rem;
        top: 0;
      }
    }

    .questionAnswer {
      width: 100%;
      min-height: 110px;
      border: none;
      padding: 1rem;
      background-color: rgba($secondary, 0.1);
      border-radius: 5px;

      &:disabled {
        opacity: 0.8;
        background-color: rgba($secondary, 0.2);
        cursor: not-allowed;
      }
    }

    .expert_appointments_container {
      min-width: 250px;
      max-width: 450px;

      table {
        tr {

          &:hover,
          &:focus,
          &:active {
            background-color: rgba($secondary, 0.1);
          }

          td {
            text-transform: capitalize;
          }
        }

        .buttonStyles {
          @include customButton($width:60px);
          @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
          min-width: unset;
          font-size: 1em;
          padding: 10px;
          margin: 5px;
          transition: all 0.25s ease-in-out;

          &:hover {
            background: $primary;
            color: $white;
          }

          &:disabled {
            opacity: 1;
          }
        }

        .pageButton {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $primary;
          background: #fff;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          font-size: 1em;
          margin: 2px;
          padding: 0;
          transition: all 0.25s ease-in-out;

          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }

    .rbc-event {
      height: 40px;
    }

    .confirm_section {
      margin: 0;
      margin-top: 3rem;
      padding: 2rem 1rem;
      background-color: $primary;
      border-radius: 20px;

      .user_image_container {
        img {
          max-width: 165px;
          height: auto;
          margin: auto;
          display: block;
        }
      }

      .appointment_information_container {
        p.h3 {
          font-weight: 300;
          color: $white;
          font-size: 1.3rem;
          text-align: center;
          min-height: 50px;
        }

        .list_text {
          color: $secondary;
          font-size: 0.9rem;
        }

        .price_text {
          color: $white;
          text-align: center;
        }

        .appointment_container {
          color: $white;
          border-top: 1px solid $white;
          padding: 1rem 1.5rem;
          font-size: 1.3rem;
          font-weight: 200;

          .appointment_header {}

          .appointment_information {}
        }
      }
    }
  }

  button.btn {
    display: block;
    margin: 10px 0;
    margin: auto;
    @include customButton();
    @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
    padding: 10px 30px;
    border-radius: 40px;
    margin-top: 1rem;
    font-weight: 500;

    &:hover {
      @include linearGradientBackground($startOpacity: 0.7, $endOpacity: 0.7);
    }
  }

  @media (max-width: 767px) {
    .expert_appointments_container {
      max-width: 70% !important;
      margin: auto;
      margin-top: 3rem;
    }

    .text_container {
      .header {
        font-size: 1.5rem;
      }

      .subheader {
        font-size: 1.3rem;
      }
    }

    .bodyContainer {

      .questionHeader,
      .price_text {
        font-size: 1.5rem;
      }
    }
  }
}

.userProfileImage {
  max-width: 100px;
  margin: auto;
  display: block;
  border: 3px solid $white;
}