.dashboardContent {
  .row {
    @include flexElement($direction: row, $align: flex-start);

    // For sidebar refear to dashboardMenu sass file
    .dashboardMain {
      @include flexElement($direction: column);
      width: 100%;
      max-width: calc(100% - 250px);
      flex: 1;
      height: 100%;
      padding-left: 2rem;
      margin-left: auto;

      .notificationButton {
        @include flexElement($direction: row, $align: center);

        svg {
          margin-left: 0.5rem;

          path {
            fill: $primary;
            transition: all 0.25s ease-in-out;
          }
        }

        &:hover {
          svg path {
            fill: $secondary;
          }
        }
      }

      .filtersContainer {
        background-color: $primary;
        border-radius: 30px;
        overflow: hidden;

        .header {
          @include flexElement($justify: space-around, $align: center);

          .svgIcon {
            margin: 0.5rem;
            width: 1.5rem;
            height: auto;

            path {
              fill: $white;
            }
          }

          h2 {
            max-width: 100%;
            flex: 0.9;
            margin: 0.5rem;
            display: inline-block;
            color: $white;
            font-weight: 300;
            border-bottom: 1px solid $white;
          }

          button.btn {
            border-radius: 20px;
          }
        }
      }

      .content {
        min-height: 100vh;

        .section-title {
          position: relative;
          margin-bottom: 1rem;

          h2 {
            font-weight: 500;
            display: inline;
            position: relative;
            background-color: $white;
            z-index: 1;
          }

          span.title-border {
            position: absolute;
            width: 100%;
            bottom: 2px;
            left: 0;
            display: inline-block;
            border-bottom: 2px solid $secondary;
            z-index: 0;
          }

          &.text-center {
            span.title-border {
              bottom: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .request_card {
          .card-title {
            p {
              font-weight: 500;
            }
          }

          .image_contsainer {
            position: relative;

            p {
              position: absolute;
              bottom: 1rem;
              left: 1rem;
              margin: 0;
              color: $white;
              font-weight: 300;
              font-size: 2rem;
            }
          }

          .card-text {
            font-size: 0.8125rem;
            font-weight: 100;
            margin: 0.5rem 0;

            .line_clamp {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .button_accept {
              @include customButton($width: auto);
              @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
              padding: 5px 15px;
              border-radius: 40px;
              border: none;
              margin: 0 0.5rem;
              min-width: unset;
            }

            .button_reject {
              @include customButton($width: auto,
                $textColor: $black,
                $borderColor: $black,
                $hoverBackColor: $primary,
                $hoverTextColor: $white);
              margin: 0 0.5rem;
              min-width: unset;
            }
          }
        }

        .expert_profile_complete {
          position: relative;
          background-color: rgba($black, 0.25);
          border-radius: 20px;
          padding: 3rem 1rem;

          img {
            width: 100px;
            height: auto;
            position: absolute;
            top: 50%;
            left: 5%;
            transform: translateY(-50%);
          }

          .inner_container {
            margin-left: 25%;

            .description {
              font-size: 2rem;

              span {
                font-size: 3.4rem;
                line-height: 3rem;
                display: block;
              }
            }

            button {
              float: right;
              @include customButton($width: auto,
                $backColor: white,
                $textColor: $primary,
                $hoverBackColor: $primary,
                $hoverTextColor: $white);
            }
          }

          @media (max-width: 767px) {
            img {
              width: 100%;
              max-width: 150px;
              height: auto;
              position: relative;
              top: unset;
              left: unset;
              margin: 1rem auto;
              display: block;
              transform: unset;
            }

            .inner_container {
              margin: auto;
              text-align: center;

              .description {
                font-size: 1.5rem;
                margin-bottom: 2rem;

                span {
                  font-size: 2.5rem;
                }
              }

              button {
                float: unset;
              }
            }
          }
        }

        .comments_container {
          .comment {
            margin: 1rem 0;

            .user_comment_image {
              width: 75px;
              height: 75px;
              margin-right: 1rem;
            }

            .card {
              border-radius: 60px;
              padding: 1rem;

              p.h4 {
                font-size: 1.12rem;
                padding-left: 1rem;
              }

              .user_comment {
                margin: 0;
                padding-left: 1rem;
                margin-right: 4rem;
                font-size: 0.875rem;
                line-height: 1rem;
              }

              .svgIcon {
                width: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);

                &:first-of-type {
                  margin-right: 3rem;
                }

                &:last-of-type {
                  margin-right: 0.8rem;
                }

                &.star svg path {
                  fill: $accent;
                }
              }
            }
          }
        }

        .scrollbar-container {
          @include boxShadowMixin($offX: map-get($pop_shadow, "offX"),
            $offY: map-get($pop_shadow, "offY"),
            $blur: map-get($pop_shadow, "blur"),
            $spread: map-get($pop_shadow, "spread"),
            $color: map-get($pop_shadow, "color"),
            $alpha: map-get($pop_shadow, "alpha"));
          min-height: 50vh;
          max-height: 400px;
          // overflow: hidden;
          border-radius: 10px;
          padding: 1rem;
        }

        .expert_appointments_section,
        .client_payments_section {

          .expert_appointments_section_scroll,
          .client_payments_section_scroll {
            min-height: 50vh;
            max-height: 200px;
            overflow: hidden;
          }

          .nav-tabs {
            border: none;

            .nav-link {
              font-size: 1.3rem;
              color: $secondary;

              &.active {
                font-weight: 500;
                color: $primary;
                border: none;
              }

              &:after {
                content: "";
                padding: 0 1rem;
                border-right: 1px solid $primary;
              }

              &:last-of-type:after {
                content: none;
              }

              &:hover {
                border: none;
              }
            }
          }

          .tab-content {
            padding: 2rem;
            background-color: rgba($secondary, 0.1);
            border-radius: 40px;

            .appointment_list {
              @include boxShadowMixin($offX: map-get($pop_shadow, "offX"),
                $offY: map-get($pop_shadow, "offY"),
                $blur: map-get($pop_shadow, "blur"),
                $spread: map-get($pop_shadow, "spread"),
                $color: map-get($pop_shadow, "color"),
                $alpha: map-get($pop_shadow, "alpha"));
              padding: 1rem;
              margin: 1rem 0;
              background-color: $white;
              border-radius: 100px;

              img {
                width: 100px;
                margin: auto;
              }

              .line_clamp {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }

              button {
                @include customButton($width: auto);
                @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
                padding: 5px 15px;
                border-radius: 40px;
                border: none;
                margin: 0 1rem;
                min-width: unset;
              }

              .button_accept {
                @include customButton($width: auto);
                @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
                padding: 5px 15px;
                border-radius: 40px;
                border: none;
                margin: 0 1rem;
                min-width: unset;
              }

              .button_reject {
                @include customButton($width: auto,
                  $textColor: $black,
                  $borderColor: $black,
                  $hoverBackColor: $primary,
                  $hoverTextColor: $white);
                background: $white;
                margin: 0 1rem;
                min-width: unset;
              }
            }
          }

          .payments_table {
            border-collapse: separate;
            border-spacing: 2rem;

            tbody {
              tr {
                @include boxShadowMixin($offX: map-get($pop_shadow, "offX"),
                  $offY: map-get($pop_shadow, "offY"),
                  $blur: map-get($pop_shadow, "blur"),
                  $spread: map-get($pop_shadow, "spread"),
                  $color: map-get($pop_shadow, "color"),
                  $alpha: map-get($pop_shadow, "alpha"));
                border-radius: 5px;

                td {
                  padding: 1rem 0;

                  img {
                    width: 80px;
                  }

                  button {
                    @include customButton($width: auto,
                      $borderColor: $white,
                      $textColor: $white,
                      $backColor: $secondary,
                      $hoverBackColor: $white,
                      $hoverTextColor: $secondary,
                      $hoverBorderColor: $secondary);
                  }
                }
              }
            }
          }
        }

        .client_payments_section {
          .tab-content {
            background-color: $white;
          }
        }
      }
    }
  }

  #notificationBar {
    width: 300px;
    height: 100%;
    background-color: $white;
    position: fixed;
    top: 0;
    right: -500px;
    z-index: 10;
    transition: all 0.25s ease-in-out;
    padding: 15px 10px;

    &.show {
      right: 0;
    }

    .notificationHeader {
      margin: 10px 0;
      border-bottom: 1px solid $black;

      p {
        margin-bottom: 3px;

        span {
          margin: 0 10px;
        }
      }
    }
  }

  #notificationBarOverlay {
    position: fixed;
    cursor: pointer;
    z-index: -10;
    width: 0px;
    height: 0px;
    top: 0;
    left: 0;

    &.show {
      z-index: 9;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.7);
    }
  }
}

.edit-button {
  position: absolute;
  cursor: pointer;
  top: 1.5rem;
  right: 1rem;
  transition: color 0.15s ease-in-out;

  svg path {
    fill: $primary;
  }

  &:hover {
    color: $secondary;

    svg path {
      fill: $secondary;
    }
  }
}

.appointmens_details_modal {
  text-align: center;

  .appointmens_details_list {
    list-style: none;
    max-width: 400px;
    margin: 3rem auto;
    text-align: left;

    li {
      p {
        font-weight: bold;

        span {
          font-weight: normal;
        }
      }

      label {
        font-weight: bold;
        font-size: 1rem;
      }

      textarea {
        font-size: 0.8rem;
        border-color: $secondary;

        &:focus,
        &:active {
          border-color: $primary;
        }
      }
    }
  }

  button.btn {
    @include customButton($width: calc(49% - 2rem));
    @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
    padding: 5px 15px;
    border-radius: 40px;
    border: none;
    margin: 0 1rem;
    min-width: unset;
  }
}

.showExpertModal {
  position: relative;

  .adminIconContainer {
    @include flexElement($justify: center, $align: center);
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: $white;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;

    i {
      font-size: 1.3rem;
      color: $grey4;
      transition: all 0.5s ease-in-out;
    }

    &.activeAdmin {
      i {
        color: $accent;
      }
    }
  }

  .servicesContainer {
    cursor: pointer;
    margin: 1rem 0.5rem;
    max-width: 31%;
    padding: 1rem;
    border: 1px solid $secondary;

    &.isDisabled {
      border: 1px solid rgba($black, 0.15);
      background-color: rgba($black, 0.15);

      svg,
      h4 {
        opacity: 0.65;
      }
    }

    .innerCard {
      border-radius: 10px;
      padding: 0.5rem 0;
      height: 100%;
      @include flexElement($justify: center, $align: center, $wrap: nowrap);

      .serviceStatus {
        position: absolute;
        left: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        border: 2px solid $primary;
        background-color: $white;
        transition: all 0.15s ease-in-out;

        &.serviceActive {
          background-color: $secondary;
          border-color: $secondary;
        }
      }
    }

    .svgService {
      width: 100%;
      padding: 1rem;
      background-color: transparent;
    }

    img.img-fluid {
      margin: auto;
      display: block;
    }

    h4 {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .questions_answers {
    .section-title {
      margin: 0;

      h2 {
        font-size: 1.5rem;
        color: $secondary;
        padding-left: 0 !important;
      }

      .title-border {}
    }
  }
}

// Styles for video chat
.videochat_modal {
  max-width: 98% !important;
  max-height: 98% !important;
  widows: 100% !important;

  #header {
    h3 {
      font-weight: 500;
      color: $accent;
    }

    .session_countdown {
      position: relative;

      .enlarge_icon {
        position: absolute;
        left: -2.5rem;
        top: 50%;
        transform: translateY(-50%);

        svg {
          width: 2rem !important;
          height: auto !important;

          path {
            fill: rgba($secondary, 0.5);
          }

          &:hover {
            path {
              fill: rgba($secondary, 0.9);
            }
          }
        }
      }

      p {
        font-weight: 500;
      }

      svg {
        height: 1rem;
        width: auto;
      }
    }
  }

  .chatcomponent_container {
    position: relative;
    width: 100%;

    .OT_subscriber {
      width: 100% !important;
      height: unset !important;
      padding-top: 56.25%;
      margin: auto;
      border-radius: 10px 10px 0 0;

      .OT_widget-container {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .OT_publisher {
      position: absolute;
      right: 2rem;
      bottom: 2.5rem;
      max-width: 200px;
      max-height: 140px;
      z-index: 1000000000000;
    }
  }

  #notes_container {
    padding: 1rem;
    margin-top: 1rem;
    height: 100%;
    overflow: hidden;
    @include boxShadowMixin($offX: map-get($pop_shadow, "offX"),
      $offY: map-get($pop_shadow, "offY"),
      $blur: map-get($pop_shadow, "blur"),
      $spread: map-get($pop_shadow, "spread"),
      $color: map-get($pop_shadow, "color"),
      $alpha: map-get($pop_shadow, "alpha"));

    .header {
      h4 {
        font-weight: 500;
        font-size: 0.9rem;
      }

      .actions {
        float: right;
        display: flex;
        flex-direction: row;

        svg {
          height: 1rem;
          width: auto;
          margin: 0 0.5rem;
          transition: all 0.2 ease-in-out;

          &:hover {
            cursor: pointer;

            path {
              fill: $accent;
              transition: all 0.2 ease-in-out;
            }
          }
        }
      }
    }

    .notes_scroll_container {
      max-height: 200px;

      .note_body {
        margin: 0.5rem;
        border-radius: 10px;
        position: relative;
        padding: 0.5rem 0.7rem;
        @include boxShadowMixin($offX: map-get($pop_shadow, "offX"),
          $offY: map-get($pop_shadow, "offY"),
          $blur: map-get($pop_shadow, "blur"),
          $spread: map-get($pop_shadow, "spread"),
          $color: map-get($pop_shadow, "color"),
          $alpha: map-get($pop_shadow, "alpha"));

        .note_number {
          margin-bottom: 0;
          font-size: 0.8rem;

          .number {
            font-weight: bold;
            margin-right: 0.5rem;
          }

          .time_lapse {
            color: $accent;
          }
        }

        .note {
          margin-bottom: 0;
          padding-right: 5rem;
          font-size: 0.95rem;
          line-height: 1rem;
        }

        .note_actions {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          svg {
            height: 1rem;
            width: auto;
            margin: 0 0.5rem;
            transition: all 0.2 ease-in-out;

            &:hover {
              cursor: pointer;

              path {
                fill: $accent;
                transition: all 0.2 ease-in-out;
              }
            }
          }
        }
      }

      .files_body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        min-height: 100%;
        background-color: rgba($secondary, 0.1);
      }
    }

    #new_note {
      position: absolute;
      z-index: 10;
      top: -9rem;
      right: -5rem;
      width: 100%;
      max-width: 400px;
      background-color: $white;
      padding: 1rem;

      .header {
        h4 {
          font-weight: 500;
          font-size: 0.9rem;
          display: inline-block;
        }

        .actions {
          float: right;
          display: flex;
          flex-direction: row;

          svg {
            height: 1rem;
            width: auto;
            margin: 0 0.5rem;
            transition: all 0.2 ease-in-out;

            &:hover {
              cursor: pointer;

              path {
                fill: $accent;
                transition: all 0.2 ease-in-out;
              }
            }
          }
        }
      }

      textarea {
        width: 100%;
        background-color: rgba($secondary, 0.1);
        border-color: $secondary;
        border-radius: 0.5rem;
        margin-top: 0.5rem;
        resize: none;
        padding: 0.3rem 0.5rem;
      }
    }
  }

  .text_chat_container {
    min-height: calc(100% - 3rem);
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 10px;
    @include boxShadowMixin($offX: map-get($pop_shadow, "offX"),
      $offY: map-get($pop_shadow, "offY"),
      $blur: map-get($pop_shadow, "blur"),
      $spread: map-get($pop_shadow, "spread"),
      $color: map-get($pop_shadow, "color"),
      $alpha: map-get($pop_shadow, "alpha"));

    .text_chat_header {
      flex-grow: 0;

      .id_chat {
        font-weight: 500;
        color: $accent;
      }
    }

    .text_chat_view {
      flex-grow: 7;
    }

    .text_chat_input {
      flex-grow: 2;
    }
  }

  @media (max-width: 767px) {
    .chatcomponent_container {
      div:nth-child(1) {
        @include flexElement($direction: column, $justify: center, $align: center, $wrap: wrap-reverse);
        width: 100%;

        div:nth-child(1) {
          order: 2;
          width: 100% !important;
          max-width: unset;
        }

        div:nth-child(2) {
          order: 1;
          width: 100%;
        }
      }

      .OT_subscriber {
        padding-top: 80%;
      }

      .OT_publisher {
        position: relative !important;
        right: unset;
        bottom: unset;
        left: unset;
        top: unset;
        height: unset !important;
        padding-top: 56.25%;

        .OT_widget-container {
          height: 100%;
          top: 0;
        }
      }

      .chat_banner {
        order: 3;
        position: relative;
      }
    }

    #notes_container {
      #new_note {
        top: 0;
        right: 0;
      }
    }
  }

  @media (max-width: 1100px) {
    #notes_container {
      #new_note {
        top: 0;
        right: 0;
      }
    }
  }
}



#screen-preview {
  // width: 100vw !important;
  // height: 100vh !important;
  z-index: -10;
  position: absolute;
  left: 0;
  top: 0;
}

body.screenShare {
  #screen-preview {
    position: fixed;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 1000000000;
  }
}

// Calification and comments
$stars: 5;
$star-size: 30px;
$star-on-color: $accent;
$star-off-color: rgba($accent, 0.15);

.rating {
  width: ($star-size * $stars) + (20px * ($stars - 1));
  height: $star-size;
  margin: auto;
  padding: 5px 10px;
  text-align: center;

  label {
    float: right;
    position: relative;
    width: $star-size;
    height: $star-size;
    cursor: pointer;

    &:not(:first-of-type) {
      margin-right: 10px;
    }

    &:before {
      content: "\2605";
      font-size: $star-size + 2px;
      color: $star-off-color;
      line-height: 1;
    }
  }

  input {
    display: none;
  }

  input:checked~label:before,
  &:not(:checked)>label:hover:before,
  &:not(:checked)>label:hover~label:before {
    color: $star-on-color;
  }
}

.videoChatQualify {
  .header {
    position: relative;

    .userImage {
      img {
        width: 170px;
        height: auto;
        border: 2px solid $white;
        @include boxShadowMixin($offX: map-get($hover_shadow, "offX"),
          $offY: map-get($hover_shadow, "offY"),
          $blur: map-get($hover_shadow, "blur"),
          $spread: map-get($hover_shadow, "spread"),
          $color: map-get($hover_shadow, "color"),
          $alpha: map-get($hover_shadow, "alpha"));
      }

      &.client {
        z-index: 2;
        position: relative;
      }

      &.expert {
        position: relative;
        z-index: 1;
        left: -2rem;
      }
    }

    .message {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      font-size: 1.5rem;
      color: $white;
      font-weight: bold;
      text-shadow: 2px 2px #000;
    }
  }

  textarea {
    width: 90%;
    margin: auto;
    height: 100px;
    border: 1px solid $secondary;
    border-radius: 6px;
    padding: 0.7rem;
    background-color: rgba($secondary, 0.1);
  }

  button.btn {
    display: block;
    margin: 10px 0;
    margin: auto;
    @include customButton();
    @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
    padding: 10px 30px;
    border-radius: 40px;
    margin-top: 1rem;
    font-weight: 500;

    &:hover {
      @include linearGradientBackground($startOpacity: 0.7, $endOpacity: 0.7);
    }
  }
}