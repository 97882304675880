.paymentInformation {
    padding: 0 !important;
    max-width: 400px !important;
    max-height: 780px !important;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid $secondary;

    .header {
        position: relative;
        background-color: $primary;
        padding: 20px 10px;
        margin-bottom: 1rem;

        h3 {
            color: $white;
            text-align: center;
        }
    }

    .userImage {
        position: relative;
        padding: 0 30px;
        margin-bottom: 2rem;
        @include flexElement($justify: center, $align: center);

        img {
            width: 80px;
            height: auto;
            border: 2px solid $white;
            display: inline-block;
            @include boxShadowMixin($offX: map-get($hover_shadow, "offX"),
                $offY: map-get($hover_shadow, "offY"),
                $blur: map-get($hover_shadow, "blur"),
                $spread: map-get($hover_shadow, "spread"),
                $color: map-get($hover_shadow, "color"),
                $alpha: map-get($hover_shadow, "alpha"));
        }

        p {
            display: inline-block;
            margin-left: 1rem;
            margin-bottom: 0;

            span {
                display: block;
                font-weight: bold;
            }
        }
    }

    button.btn {
        display: block;
        margin: 10px 0;
        margin: auto;
        @include customButton();
        @include linearGradientBackground($startColor: $secondary, $endColor: $primary);
        padding: 10px 30px;
        border-radius: 40px;
        margin-top: 1rem;
        font-weight: 500;

        &:hover {
            @include linearGradientBackground($startOpacity: 0.7, $endOpacity: 0.7);
        }
    }
}